import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import {
    FormBuilderInputModule,
    LayoutModule,
    IconsModule,
    FormBuilderValidationModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TranslateModule } from 'app/modules/translate.module';
import { SearchUsersPageComponent } from '@monsido/pages/backend-admin/support/search-users/search-users.component';
import { SearchUsersService } from '@monsido/pages/backend-admin/support/search-users/search-users.service';
import { SEARCH_USERS_STATES } from '@monsido/pages/backend-admin/support/search-users/search-users.state';

@NgModule({
    imports: [
        AngularFormModule,
        CommonModule,
        TranslateModule,
        FormBuilderInputModule,
        FormBuilderValidationModule,
        IconsModule,
        LayoutModule,
        UIRouterModule.forChild({ states: SEARCH_USERS_STATES }),
    ],
    providers: [SearchUsersService],
    declarations: [SearchUsersPageComponent],
})
export class SearchUsersModule {}
